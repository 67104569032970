import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "GeneralView" */ '@/views/common/layout/LoggedView.vue'
      ),
    children: [
      {
        path: '',
        name: 'TaskAreaRedirect',
        redirect: { name: 'TaskAreaBoard' },
      },

      //task
      {
        path: 'task',
        name: 'TaskArea',
        component: () =>
          import(
            /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
          ),

        children: [
          {
            path: '',
            name: 'TaskAreaBoardRedirect',
            redirect: { name: 'TaskAreaBoard' },
          },

          //board
          {
            path: 'board',
            name: 'TaskAreaBoard',
            component: () =>
              import(
                /* webpackChunkName: "TaskAreaBoard" */ '@/views/BoardView.vue'
              ),
          },
        ],
      },

      //config
      {
        path: 'config',
        name: 'ConfigArea',
        component: () =>
          import(
            /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
          ),

        children: [
          {
            path: '',
            name: 'ConfigEventsRedirect',
            redirect: { name: 'ConfigEvents' },
          },

          //tasks
          {
            path: 'tasks',
            name: 'ConfigTasks',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ConfigTasksKindsListRedirect',
                redirect: { name: 'ConfigTasksKindsList' },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: '/error',
    name: 'Errors',
    component: () =>
      import(
        /* webpackChunkName: "PublicLayout" */ '@/views/common/layout/PublicView.vue'
      ),

    children: [
      {
        path: '503',
        name: 'Error503',
        component: () =>
          import(
            /* webpackChunkName: "Error503" */ '@/views/errors/503View.vue'
          ),
      },
      {
        path: '404',
        name: 'Error404',
        component: () =>
          import(
            /* webpackChunkName: "Error404" */ '@/views/errors/404View.vue'
          ),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Error404' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }

    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
  //   if (to.matched.some((record) => record.meta.authorizationRequired)) {
  //     if (store.getters['session/getAuthStatus']) {
  //       next()
  //       return
  //     }
  //     console.log('/users/login')
  //     //next('/users/login')
  //   } else {
  //     next()
  //   }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
